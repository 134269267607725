#explore{
    height: 100vh;
    background:linear-gradient(0deg, rgba(0, 0, 0, 0.61), rgba(0, 0, 0, 0.61)), url("../../../Assets/Images/craw-bg.jpg");
    background-position: center;
}
.store{
    background: url("../../../Assets/Images/street.jpg");
    background-position: center;
    background-size: cover;
    height: 100vh;
}
.explore-info{
   margin-top: 100px;
    padding-left: 100px;
}
@media only screen and (max-width: 1351px){
    .store{
        display: none;
    }
    #explore{
        height: auto;
    }
    .explore-info{
        padding-top: 100px;
        padding-bottom: 100px;
        padding-left: 100px;
        width: 90%;
        background:linear-gradient(0deg, rgba(0, 0, 0, 0.61), rgba(0, 0, 0, 0.61)), url("../../../Assets/Images/craw-bg.jpg");
        
    }

}

  .table-font{
    margin: 0;
    margin-right: 50px;
    margin-bottom: 30px;
    font-size: 35px;
  }
  