#topbar {
    background: rgba(18, 15, 15, 0.72);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 10px 10px 10px 20px;
    display: flex;
    z-index: 10;
  }

  #topbar > .location {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #topbar > .location > img {
    margin-right: 20px;
  }

  #topbar > .number {
    display: flex;
    align-items: center;
    margin-left: auto; 
    margin-right: 30px;
  }

  #topbar > .number > .red-button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .number > .red-button > img{
    width: 25px;
    margin-right: 10px;
  }