@import url('https://fonts.googleapis.com/css2?family=Amiko&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@200&display=swap');

body{
  background-color: #120F0F;
  color: white;
  overflow-x: clip;
}
h1{
  font-family: 'Amiko';
  font-style: normal;
  font-weight: 400;
  font-size: 60px;
  line-height: 107px;
}
p, h2{
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 52px;
}
h2{
  font-size: 50px;
}
p{
  font-size: 20px;
}
.smallText{
  font-size: 30px;
}
h3{
  font-family: 'Amiko';
  font-size: 25px;
}
a{
  font-family: 'Amiko';
  font-size: 25px;
  text-decoration: none;
  color: white;
  white-space: nowrap;
  transition: ease-in-out 2s;
}
a:hover{
  text-decoration: underline;
}
button{
  font-family: 'Amiko';
  padding: 10px 20px 10px 20px;
  background: linear-gradient(180deg, #FFFFFF 0%, #B0B0B0 100%);
  border-radius: 8px; 
  font-size: 25px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out, background-color 0.2s ease-in-out;
}
.red-button {
  color: white;
  background: linear-gradient(180deg, #CF2929 0%, rgba(207, 41, 41, 0.55) 100%), #000000;
  padding: 10px 20px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out, background-color 0.2s ease-in-out;
}
button, .red-button:hover {
  transform: translateY(-3px);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
}

button:active, .red-button:active {
  transform: translateY(1px);
  box-shadow: none;
}
.slider {
  overflow: hidden;
}

.slider-wrapper {
  display: flex;
  transition: transform ease-out 0.45s;
}

.slide {
  flex: 0 0 25%; 
  height: 200px;
  position: relative;
  overflow: hidden;
}

.slide img {
  width: 100%;
  height: 100%;
  object-fit: cover; 
}
.carousel-image {
  width: 96%;
  height: 300px;
  object-fit: cover;
}
@media only screen and (max-width: 767px){
  p{
    font-size: 20px;;
  }
  .smallText{
    font-size: 15px;
  }

  .carousel-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  h1{
    font-size: 20px;
  }
  h2{
    font-size: 17px;
  }
}
.plateOne, .plateTwo{
  z-index: 3;
  position: absolute;
 
}
.plateOne{
  top: 300px;
  right: 0;
  width: 460px;
}
.plateTwo{
  top: 1600px;
  left: 0;
  width: 400px;
  
}

@media only screen and (max-width: 1071px){
  .plateOne, .plateTwo{
    display: none;
  
  }
}
.BrainhubCarousel__arrows{
  background: #CF2929 !important;
  margin: 20px;
}

#footer{
  height: 150px;
  padding: 30px;
  margin-bottom: 50px;
}
.footer-text{
  font-size: 20px;
  margin-bottom: 0px;
}
.phone-btn{
  margin-top: 20px;
}
.address-footer{
  white-space: pre-wrap;
}
.watermark{
  margin-top: 30px;
  margin-bottom: 30px;
  text-align: center;
  position: absolute;
  right: 0;
  padding-right: 20px;
}