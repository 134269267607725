#landing{
    background:linear-gradient(0deg, rgba(0, 0, 0, 0.61), rgba(0, 0, 0, 0.61)), url("../../../Assets/Images/corn-plate.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    padding: 200px 60px 100px 60px;
}
#landing > h1{
    margin-top: 0px;
    margin-bottom: -40px;
}
.order-buttons > button{
   margin-right: 10px;
   margin-bottom: 10px;
}
.order-buttons > button > img{
    width: 200px;
}
@media only screen and (max-width: 571px){
    #landing{  
        padding: 100px 10px 100px 10px;
        text-align: center;
    }

    
}