#review{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
  
    background-size: cover;
    height: 100%;
}
#review > img{
    
    padding: 10px;
    margin-right: 40px;
    height: 100px;
}
.mobile-img{
    display: none;
}

#review > .review-container{
    padding: 20px;
}
@media only screen and (max-width: 859px){
    #review{
        display: block;
        padding: 0px;
    }
    .company-img{
       position: absolute;
       z-index: 1;
       opacity: 10%;
       right: 0;
    }
    .review-container{
        z-index: 2;

    }
    .BrainhubCarousel__arrows{
        display: none;
    }

}